var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "card" }, [
    _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "pull-right mt-2" }, [
        !_vm.belongBranchesListLoading
          ? _c(
              "div",
              { staticClass: "d-inline-block" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "belongBranchManage",
                        params: { id: _vm.userId }
                      }
                    }
                  },
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "ml-3",
                        class: _vm.$style.filledBtn,
                        attrs: {
                          type: "primary",
                          autoInsertSpaceInButton: "false"
                        }
                      },
                      [_vm._v("管理")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c("h2", [_vm._v("所属支店一覧")])
    ]),
    _c(
      "div",
      { staticClass: "card-body" },
      [
        _vm.belongBranchesListLoading
          ? _c(
              "div",
              { staticClass: "text-center" },
              [_c("a-spin", { attrs: { tip: "Loading..." } })],
              1
            )
          : _vm._e(),
        !_vm.belongBranchesListLoading
          ? _c("a-list", {
              attrs: {
                "item-layout": "horizontal",
                "data-source": _vm.branches,
                pagination: true
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "renderItem",
                    fn: function(item) {
                      return _c(
                        "a-list-item",
                        {},
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "branchShow",
                                  params: { id: item.id }
                                }
                              }
                            },
                            [
                              _c(
                                "a-list-item-meta",
                                { attrs: { description: item.comment } },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "list-name",
                                      attrs: { slot: "title" },
                                      slot: "title"
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }
                  }
                ],
                null,
                false,
                1948555846
              )
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }