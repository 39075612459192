<template>
  <section class="card">
    <div class="card-header">
      <div class="pull-right mt-2">
        <div v-if="!belongBranchesListLoading" class="d-inline-block">
          <router-link :to="{ name: 'belongBranchManage', params: { id: userId }}">
            <a-button type="primary" class="ml-3" :class="$style.filledBtn" autoInsertSpaceInButton="false">管理</a-button>
          </router-link>
        </div>
      </div>
      <h2>所属支店一覧</h2>
    </div>
    <div class="card-body">
      <div v-if="belongBranchesListLoading" class="text-center">
        <a-spin tip="Loading..." />
      </div>
      <a-list
        v-if="!belongBranchesListLoading"
        item-layout="horizontal"
        :data-source="branches"
        :pagination="true"
      >
        <a-list-item slot="renderItem" slot-scope="item">
          <router-link :to="{ name: 'branchShow', params: { id: item.id }}">
            <a-list-item-meta
              :description="item.comment"
            >
              <p slot="title" class="list-name">{{ item.name }}</p>
            </a-list-item-meta>
          </router-link>
        </a-list-item>
      </a-list>
    </div>
  </section>
</template>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<script>
import moment from 'moment'

export default {
  name: 'BelongBranchesList',
  props: [
    'belongBranchesListLoading',
    'branches',
    'userId',
  ],
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY/MM/DD HH:mm')
    },
  },
}
</script>
