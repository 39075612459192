var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", { class: _vm.$style.userName }, [_vm._v(_vm._s(_vm.user.name))]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("section", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("div", { staticClass: "pull-right mt-2" }, [
                _c(
                  "div",
                  { staticClass: "d-inline-block" },
                  [
                    !_vm.loading
                      ? _c(
                          "a-dropdown",
                          { class: _vm.$style.operationBtn },
                          [
                            _c(
                              "span",
                              { staticClass: "ant-dropdown-link" },
                              [
                                _c("i", { staticClass: "icmn-cog mr-2" }),
                                _c(
                                  "span",
                                  { staticClass: "hidden-lg-down mr-2" },
                                  [_vm._v("操作")]
                                ),
                                _c("a-icon", { attrs: { type: "down" } })
                              ],
                              1
                            ),
                            _c(
                              "a-menu",
                              { attrs: { slot: "overlay" }, slot: "overlay" },
                              [
                                _c(
                                  "a-menu-item",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "userUpdate",
                                            params: { id: _vm.user.id }
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "icmn-pencil mr-2"
                                        }),
                                        _c("span", [_vm._v("編集")])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "a-menu-item",
                                  { on: { click: _vm.showUnavailableModal } },
                                  [
                                    _c("i", {
                                      staticClass: "icmn-warning mr-2"
                                    }),
                                    _c("span", [_vm._v("アカウント停止操作")])
                                  ]
                                ),
                                _c(
                                  "a-menu-item",
                                  { on: { click: _vm.showModal } },
                                  [
                                    _c("i", { staticClass: "icmn-bin mr-2" }),
                                    _c("span", [_vm._v("削除")])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("a-modal", {
                      attrs: {
                        title: _vm.title,
                        visible: _vm.visible,
                        "confirm-loading": _vm.confirmLoading,
                        okText: _vm.okText,
                        okType: "danger",
                        cancelText: "キャンセル"
                      },
                      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
                    })
                  ],
                  1
                )
              ]),
              _c("h2", [_vm._v("基本情報")])
            ]),
            _c("div", { staticClass: "card-body" }, [
              _vm.loading
                ? _c(
                    "div",
                    { staticClass: "text-center" },
                    [_c("a-spin", { attrs: { tip: "Loading..." } })],
                    1
                  )
                : _vm._e(),
              !_vm.loading
                ? _c("ul", { class: _vm.$style.basicInfoList }, [
                    _vm.user.unavailable
                      ? _c("li", { staticStyle: { color: "red" } }, [
                          _vm._v("アカウント停止中")
                        ])
                      : _vm._e(),
                    _c("li", [
                      _c("span", { class: _vm.$style.infoLabel }, [
                        _vm._v("権限:")
                      ]),
                      _c("span", { class: _vm.$style.infoValue }, [
                        _vm._v(_vm._s(_vm.role))
                      ])
                    ]),
                    _c("li", [
                      _c("span", { class: _vm.$style.infoLabel }, [
                        _vm._v("メールアドレス:")
                      ]),
                      _c("span", { class: _vm.$style.infoValue }, [
                        _vm._v(" " + _vm._s(_vm.user.email))
                      ])
                    ]),
                    _vm.user.roll_call_agent
                      ? _c("li", [
                          _c("span", { class: _vm.$style.infoLabel }, [
                            _vm._v("電話番号:")
                          ]),
                          _c("span", { class: _vm.$style.infoValue }, [
                            _vm._v(_vm._s(_vm.user.tel))
                          ])
                        ])
                      : _vm._e(),
                    _vm.user.roll_call_agent
                      ? _c("li", [
                          _c("span", { class: _vm.$style.infoLabel }, [
                            _vm._v("コール回数:")
                          ]),
                          _c("span", { class: _vm.$style.infoValue }, [
                            _vm._v(_vm._s(_vm.user.roll_call_count))
                          ])
                        ])
                      : _vm._e(),
                    _c("li", [
                      _c("span", { class: _vm.$style.infoLabel }, [
                        _vm._v("スマホ機種:")
                      ]),
                      _c("span", { class: _vm.$style.infoValue }, [
                        _vm._v(_vm._s(_vm.user.mobile_model))
                      ])
                    ]),
                    _c("li", [
                      _c("span", { class: _vm.$style.infoLabel }, [
                        _vm._v("スマホのSIM:")
                      ]),
                      _c("span", { class: _vm.$style.infoValue }, [
                        _vm._v(_vm._s(_vm.user.mobile_sim))
                      ])
                    ]),
                    _c("li", [
                      _c("span", { class: _vm.$style.infoLabel }, [
                        _vm._v("最終ログイン日時:")
                      ]),
                      _c("span", { class: _vm.$style.infoValue }, [
                        _vm._v(_vm._s(_vm._f("moment")(_vm.user.last_login)))
                      ])
                    ]),
                    _c("li", [
                      _c("span", { class: _vm.$style.infoLabel }, [
                        _vm._v("作成日:")
                      ]),
                      _c("span", { class: _vm.$style.infoValue }, [
                        _vm._v(_vm._s(_vm._f("moment")(_vm.user.created_at)))
                      ])
                    ]),
                    _vm.myRole === 0
                      ? _c("li", [
                          _c("span", { class: _vm.$style.infoLabel }, [
                            _vm._v("点呼代行開始日:")
                          ]),
                          _c("span", { class: _vm.$style.infoValue }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("date_str")(
                                  _vm.user.roll_call_date_start
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.myRole === 0
                      ? _c("li", [
                          _c("span", { class: _vm.$style.infoLabel }, [
                            _vm._v("点呼代行終了日:")
                          ]),
                          _c("span", { class: _vm.$style.infoValue }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("date_str")(_vm.user.roll_call_date_end)
                              )
                            )
                          ])
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _c("div", { staticClass: "col-lg-6" }, [
          _c("section", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("div", { staticClass: "pull-right mt-2" }, [
                _c(
                  "div",
                  { staticClass: "d-inline-block" },
                  [
                    _vm.myRole <= 1
                      ? _c(
                          "router-link",
                          { attrs: { to: { name: "userReadRange" } } },
                          [
                            _c(
                              "a-button",
                              {
                                staticClass: "ml-3",
                                class: _vm.$style.filledBtn
                              },
                              [_vm._v("管理")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c("h2", [_vm._v("追加閲覧可能グループ")])
            ]),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _vm.readGroupsLoading
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [_c("a-spin", { attrs: { tip: "Loading..." } })],
                      1
                    )
                  : _vm._e(),
                !_vm.readGroupsLoading
                  ? _c("a-list", {
                      attrs: {
                        "item-layout": "horizontal",
                        "data-source": _vm.groups,
                        pagination: true
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "renderItem",
                            fn: function(item) {
                              return _c(
                                "a-list-item",
                                {},
                                [
                                  _c(
                                    "a-list-item-meta",
                                    { attrs: { description: item.comment } },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "list-name",
                                          attrs: { slot: "title" },
                                          slot: "title"
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        2809041212
                      )
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "col-lg-6" },
          [
            _c("belong-branches-list", {
              attrs: {
                belongBranchesListLoading: _vm.belongBranchesListLoading,
                branches: _vm.branches,
                userId: _vm.user.id
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "col-lg-12" }, [
          _c("section", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("div", { staticClass: "pull-right mt-2" }, [
                _c(
                  "div",
                  { staticClass: "d-inline-block" },
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "ml-3",
                        class: _vm.$style.filledBtn,
                        attrs: {
                          loading: _vm.driversLisenceConfirmLoading,
                          type: "primary",
                          autoInsertSpaceInButton: "false"
                        },
                        on: { click: _vm.onMakeDriversLisence }
                      },
                      [_vm._v("追加")]
                    )
                  ],
                  1
                )
              ]),
              _c("h2", [_vm._v("運転免許")])
            ]),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _vm.driversLisenceLoading
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [_c("a-spin", { attrs: { tip: "Loading..." } })],
                      1
                    )
                  : _vm._e(),
                !_vm.driversLisenceLoading
                  ? _c("a-list", {
                      attrs: {
                        "item-layout": "horizontal",
                        "data-source": _vm.driversLisences,
                        pagination: false
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "renderItem",
                            fn: function(item) {
                              return _c(
                                "a-list-item",
                                {},
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { slot: "actions" },
                                      slot: "actions"
                                    },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.updateDriversLisence(
                                                item.id,
                                                item.number,
                                                item.type,
                                                item.condition,
                                                item.date_acquisition,
                                                item.date_expiration,
                                                item.address,
                                                item.emergency_contact_full_name,
                                                item.emergency_contact_relation_ship,
                                                item.emergency_contact_tel
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("編集")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("a-list-item-meta", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "list-name",
                                        attrs: { slot: "title" },
                                        slot: "title"
                                      },
                                      [_c("p", [_vm._v(_vm._s(item.number))])]
                                    )
                                  ])
                                ],
                                1
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        813876859
                      )
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      ]),
      _c("drive-data-list", {
        key: _vm.$route.fullPath + "_UserDriveDataView",
        attrs: { target: "user", targetId: _vm.userId }
      }),
      _vm.user.allow_driving_schedule === 1 &&
      _vm.myRole <= 2 &&
      _vm.isAcquirableUserOrMe === 1
        ? _c("driving-schedule-list", {
            key: _vm.$route.fullPath + "_UserDrivingScheduleView",
            attrs: { targetUserId: _vm.userId, targetUserName: _vm.user.name }
          })
        : _vm._e(),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.modalDriversLisenceText,
            visible: _vm.modalDriversLisenceVisible
          },
          on: { cancel: _vm.handleCancelDriversLisenceModal }
        },
        [
          _c(
            "div",
            [
              _c(
                "a-form-model",
                {
                  ref: "driversLisenceRef",
                  attrs: {
                    rules: _vm.driversLisenceRules,
                    model: _vm.driversLisenceForm
                  }
                },
                [
                  _c(
                    "a-form-model-item",
                    {
                      ref: "number",
                      attrs: { label: "免許証番号", prop: "number" }
                    },
                    [
                      _c("a-input", {
                        on: {
                          blur: function() {
                            _vm.$refs.number.onFieldBlur()
                          }
                        },
                        model: {
                          value: _vm.driversLisenceForm.number,
                          callback: function($$v) {
                            _vm.$set(_vm.driversLisenceForm, "number", $$v)
                          },
                          expression: "driversLisenceForm.number"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "type",
                      attrs: { label: "免許種類 (必須項目)", prop: "type" }
                    },
                    [
                      _c("a-checkbox-group", {
                        attrs: { options: _vm.driversLisenceType },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function(ref) {
                              var value = ref.value
                              var record = ref.record
                              return _c(
                                "span",
                                { staticStyle: { color: "red" } },
                                [_vm._v(_vm._s(record.label))]
                              )
                            }
                          }
                        ]),
                        model: {
                          value: _vm.driversLisenceForm.type,
                          callback: function($$v) {
                            _vm.$set(_vm.driversLisenceForm, "type", $$v)
                          },
                          expression: "driversLisenceForm.type"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "condition",
                      attrs: { label: "免許条件", prop: "condition" }
                    },
                    [
                      _c("a-input", {
                        on: {
                          blur: function() {
                            _vm.$refs.condition.onFieldBlur()
                          }
                        },
                        model: {
                          value: _vm.driversLisenceForm.condition,
                          callback: function($$v) {
                            _vm.$set(_vm.driversLisenceForm, "condition", $$v)
                          },
                          expression: "driversLisenceForm.condition"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-config-provider",
                    { attrs: { locale: _vm.locale } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          ref: "dateAcquisition",
                          attrs: {
                            label: "免許取得年月日",
                            prop: "dateAcquisition"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            attrs: { placeholder: "免許取得年月日" },
                            model: {
                              value: _vm.dateAcquisition,
                              callback: function($$v) {
                                _vm.dateAcquisition = $$v
                              },
                              expression: "dateAcquisition"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-config-provider",
                    { attrs: { locale: _vm.locale } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          ref: "dateExpiration",
                          attrs: {
                            label: "免許証有効期限年月日 (必須項目)",
                            prop: "dateExpiration"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            attrs: { placeholder: "免許証有効期限年月日" },
                            model: {
                              value: _vm.dateExpiration,
                              callback: function($$v) {
                                _vm.dateExpiration = $$v
                              },
                              expression: "dateExpiration"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "address",
                      attrs: { label: "住所", prop: "address" }
                    },
                    [
                      _c("a-input", {
                        on: {
                          blur: function() {
                            _vm.$refs.address.onFieldBlur()
                          }
                        },
                        model: {
                          value: _vm.driversLisenceForm.address,
                          callback: function($$v) {
                            _vm.$set(_vm.driversLisenceForm, "address", $$v)
                          },
                          expression: "driversLisenceForm.address"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "emergency_contact_full_name",
                      attrs: {
                        label: "緊急連絡先：氏名",
                        prop: "emergency_contact_full_name"
                      }
                    },
                    [
                      _c("a-input", {
                        on: {
                          blur: function() {
                            _vm.$refs.emergency_contact_full_name.onFieldBlur()
                          }
                        },
                        model: {
                          value:
                            _vm.driversLisenceForm.emergency_contact_full_name,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.driversLisenceForm,
                              "emergency_contact_full_name",
                              $$v
                            )
                          },
                          expression:
                            "driversLisenceForm.emergency_contact_full_name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "emergency_contact_relation_ship",
                      attrs: {
                        label: "緊急連絡先：関係",
                        prop: "emergency_contact_relation_ship"
                      }
                    },
                    [
                      _c("a-input", {
                        on: {
                          blur: function() {
                            _vm.$refs.emergency_contact_relation_ship.onFieldBlur()
                          }
                        },
                        model: {
                          value:
                            _vm.driversLisenceForm
                              .emergency_contact_relation_ship,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.driversLisenceForm,
                              "emergency_contact_relation_ship",
                              $$v
                            )
                          },
                          expression:
                            "driversLisenceForm.emergency_contact_relation_ship"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "emergency_contact_tel",
                      attrs: {
                        label: "緊急連絡先：TEL",
                        prop: "emergency_contact_tel"
                      }
                    },
                    [
                      _c("a-input", {
                        on: {
                          blur: function() {
                            _vm.$refs.emergency_contact_tel.onFieldBlur()
                          }
                        },
                        model: {
                          value: _vm.driversLisenceForm.emergency_contact_tel,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.driversLisenceForm,
                              "emergency_contact_tel",
                              $$v
                            )
                          },
                          expression: "driversLisenceForm.emergency_contact_tel"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c("div", { staticClass: "clearfix" }, [
              _c(
                "div",
                { staticClass: "float-left" },
                [
                  _vm.targetDriversLisenceId
                    ? _c(
                        "a-button",
                        {
                          key: "submit",
                          attrs: {
                            type: "danger",
                            loading: _vm.driversLisenceConfirmLoading
                          },
                          on: { click: _vm.handleDeleteDriversLisence }
                        },
                        [_vm._v("\n            削除\n          ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "float-right" },
                [
                  _c(
                    "a-button",
                    {
                      key: "back",
                      on: { click: _vm.handleCancelDriversLisenceModal }
                    },
                    [_vm._v("\n            キャンセル\n          ")]
                  ),
                  _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: {
                        type: "primary",
                        loading: _vm.driversLisenceConfirmLoading
                      },
                      on: { click: _vm.handleOkDriversLisence }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.modalDriversLisenceText) +
                          "\n          "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }