
<template>
  <div>
    <h1 :class="$style.userName">{{user.name}}</h1>
    <div class="row">
      <div class="col-lg-12">
        <section class="card">
          <div class="card-header">
            <div class="pull-right mt-2">
              <div class="d-inline-block">
                <a-dropdown v-if="!loading" :class="$style.operationBtn">
                  <span class="ant-dropdown-link">
                    <i class="icmn-cog mr-2"/>
                    <span class="hidden-lg-down mr-2">操作</span>
                    <a-icon type="down"/>
                  </span>
                  <a-menu slot="overlay">
                    <a-menu-item>
                      <router-link :to="{ name: 'userUpdate', params: { id: user.id }}">
                        <i class="icmn-pencil mr-2"/>
                        <span>編集</span>
                      </router-link>
                    </a-menu-item>
                    <a-menu-item @click="showUnavailableModal">
                      <i class="icmn-warning mr-2"/>
                      <span>アカウント停止操作</span>
                    </a-menu-item>
                    <a-menu-item @click="showModal">
                      <i class="icmn-bin mr-2"/>
                      <span>削除</span>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
                <a-modal
                  :title="title"
                  :visible="visible"
                  :confirm-loading="confirmLoading"
                  :okText="okText"
                  okType='danger'
                  cancelText='キャンセル'
                  @ok="handleOk"
                  @cancel="handleCancel"
                />
              </div>
            </div>
            <h2>基本情報</h2>
          </div>
          <div class="card-body">
            <div v-if="loading" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <ul v-if="!loading" :class="$style.basicInfoList">
              <li v-if="user.unavailable" style="color: red;">アカウント停止中</li>
              <li><span :class="$style.infoLabel">権限:</span><span :class="$style.infoValue">{{role}}</span></li>
              <li><span :class="$style.infoLabel">メールアドレス:</span><span :class="$style.infoValue"> {{user.email}}</span></li>
              <li v-if="user.roll_call_agent"><span :class="$style.infoLabel">電話番号:</span><span :class="$style.infoValue">{{user.tel}}</span></li>
              <li v-if="user.roll_call_agent"><span :class="$style.infoLabel">コール回数:</span><span :class="$style.infoValue">{{user.roll_call_count}}</span></li>
              <li><span :class="$style.infoLabel">スマホ機種:</span><span :class="$style.infoValue">{{user.mobile_model}}</span></li>
              <li><span :class="$style.infoLabel">スマホのSIM:</span><span :class="$style.infoValue">{{user.mobile_sim}}</span></li>
              <li><span :class="$style.infoLabel">最終ログイン日時:</span><span :class="$style.infoValue">{{user.last_login | moment}}</span></li>
              <li><span :class="$style.infoLabel">作成日:</span><span :class="$style.infoValue">{{user.created_at | moment}}</span></li>
              <li v-if="myRole === 0"><span :class="$style.infoLabel">点呼代行開始日:</span><span :class="$style.infoValue">{{user.roll_call_date_start | date_str}}</span></li>
              <li v-if="myRole === 0"><span :class="$style.infoLabel">点呼代行終了日:</span><span :class="$style.infoValue">{{user.roll_call_date_end | date_str}}</span></li>
            </ul>
          </div>
        </section>
      </div>
      <div class="col-lg-6">
          <section class="card">
            <div class="card-header">
              <div class="pull-right mt-2">
                <div class="d-inline-block">
                  <router-link :to="{ name: 'userReadRange' }" v-if="myRole <= 1">
                    <a-button class="ml-3" :class="$style.filledBtn">管理</a-button>
                  </router-link>
                </div>
              </div>
              <h2>追加閲覧可能グループ</h2>
            </div>
            <div class="card-body">
              <div v-if="readGroupsLoading" class="text-center">
                <a-spin tip="Loading..." />
              </div>
              <a-list
                v-if="!readGroupsLoading"
                item-layout="horizontal"
                :data-source="groups"
                :pagination="true"
              >
                <a-list-item slot="renderItem" slot-scope="item">
                  <a-list-item-meta
                    :description="item.comment"
                  >
                    <p slot="title" class="list-name">{{ item.name }}</p>
                  </a-list-item-meta>
                </a-list-item>
              </a-list>
            </div>
          </section>
        </div>
      <div class="col-lg-6">
        <belong-branches-list
          :belongBranchesListLoading="belongBranchesListLoading"
          :branches="branches"
          :userId="user.id"
        />
      </div>
    <div class="col-lg-12">
      <section class="card">
          <div class="card-header">
            <div class="pull-right mt-2">
              <div class="d-inline-block">
                <a-button :loading="driversLisenceConfirmLoading" type="primary" class="ml-3" autoInsertSpaceInButton="false" @click="onMakeDriversLisence" :class="$style.filledBtn">追加</a-button>
              </div>
            </div>
            <h2>運転免許</h2>
          </div>
          <div class="card-body">
            <div v-if="driversLisenceLoading" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <a-list
              v-if="!driversLisenceLoading"
              item-layout="horizontal"
              :data-source="driversLisences"
              :pagination="false"
            >
              <a-list-item slot="renderItem" slot-scope="item">
                <a slot="actions"><a-button @click="updateDriversLisence(item.id, item.number, item.type, item.condition, item.date_acquisition, item.date_expiration, item.address, item.emergency_contact_full_name, item.emergency_contact_relation_ship, item.emergency_contact_tel)">編集</a-button></a>
                <a-list-item-meta>
                  <div slot="title" class="list-name">
                    <p>{{ item.number }}</p>
                  </div>
                </a-list-item-meta>
              </a-list-item>
            </a-list>
          </div>
        </section>
      </div>
    </div>
    <drive-data-list
      target="user"
      :targetId="userId"
      :key="$route.fullPath + '_UserDriveDataView'"
    />
    <driving-schedule-list
      v-if="user.allow_driving_schedule === 1 && myRole <= 2 && isAcquirableUserOrMe === 1"
      :targetUserId="userId"
      :targetUserName="user.name"
      :key="$route.fullPath + '_UserDrivingScheduleView'"
    />
    <a-modal
      :title="modalDriversLisenceText"
      :visible="modalDriversLisenceVisible"
      @cancel="handleCancelDriversLisenceModal"
    >
      <div>
        <a-form-model
          ref="driversLisenceRef"
          :rules="driversLisenceRules"
          :model="driversLisenceForm"
        >
          <a-form-model-item ref="number" label="免許証番号" prop="number">
            <a-input
              v-model="driversLisenceForm.number"
              @blur="() => { $refs.number.onFieldBlur() }"
            />
          </a-form-model-item>

          <a-form-model-item label="免許種類 (必須項目)" prop="type" ref="type">
            <a-checkbox-group
              v-model="driversLisenceForm.type"
              :options="driversLisenceType"
            >
              <span slot="label" slot-scope="{ value, record }" style="color: red">{{ record.label }}</span>
            </a-checkbox-group>
          </a-form-model-item>

          <a-form-model-item ref="condition" label="免許条件" prop="condition">
            <a-input
              v-model="driversLisenceForm.condition"
              @blur="() => { $refs.condition.onFieldBlur() }"
            />
          </a-form-model-item>

          <a-config-provider :locale="locale">
            <a-form-model-item ref="dateAcquisition" label="免許取得年月日" prop="dateAcquisition">
              <a-date-picker placeholder="免許取得年月日" v-model="dateAcquisition" />
            </a-form-model-item>
          </a-config-provider>

          <a-config-provider :locale="locale">
            <a-form-model-item ref="dateExpiration" label="免許証有効期限年月日 (必須項目)" prop="dateExpiration">
              <a-date-picker placeholder="免許証有効期限年月日" v-model="dateExpiration" />
            </a-form-model-item>
          </a-config-provider>

          <a-form-model-item ref="address" label="住所" prop="address">
            <a-input
              v-model="driversLisenceForm.address"
              @blur="() => { $refs.address.onFieldBlur() }"
            />
          </a-form-model-item>

          <a-form-model-item ref="emergency_contact_full_name" label="緊急連絡先：氏名" prop="emergency_contact_full_name">
            <a-input
              v-model="driversLisenceForm.emergency_contact_full_name"
              @blur="() => { $refs.emergency_contact_full_name.onFieldBlur() }"
            />
          </a-form-model-item>

          <a-form-model-item ref="emergency_contact_relation_ship" label="緊急連絡先：関係" prop="emergency_contact_relation_ship">
            <a-input
              v-model="driversLisenceForm.emergency_contact_relation_ship"
              @blur="() => { $refs.emergency_contact_relation_ship.onFieldBlur() }"
            />
          </a-form-model-item>

          <a-form-model-item ref="emergency_contact_tel" label="緊急連絡先：TEL" prop="emergency_contact_tel">
            <a-input
              v-model="driversLisenceForm.emergency_contact_tel"
              @blur="() => { $refs.emergency_contact_tel.onFieldBlur() }"
            />
          </a-form-model-item>

        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <div class="float-left">
            <a-button v-if="targetDriversLisenceId" key="submit" type="danger" :loading="driversLisenceConfirmLoading" @click="handleDeleteDriversLisence">
              削除
            </a-button>
          </div>
          <div class="float-right">
            <a-button key="back" @click="handleCancelDriversLisenceModal">
              キャンセル
            </a-button>
            <a-button key="submit" type="primary" :loading="driversLisenceConfirmLoading" @click="handleOkDriversLisence">
              {{ modalDriversLisenceText }}
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<script>
import Vue from 'vue'
import moment from 'moment'
import storeVue from '@/store'
import BelongBranchesList from '@/components/ListComponents/BelongBranches'
import DriveDataList from '@/views/drive_data/list'
import DrivingScheduleList from '@/views/driving_schedule'
import { objectData } from '@/services/object'
import { enumData } from '@/services/enum'
import jaJa from 'ant-design-vue/es/locale/ja_JP'

export default {
  components: {
    BelongBranchesList,
    DriveDataList,
    DrivingScheduleList,
  },
  data() {
    return {
      locale: jaJa,
      userId: this.$route.params.id,
      loading: true,
      belongBranchesListLoading: true,
      readGroupsLoading: true,
      user: Object.assign({}, objectData.user),
      branches: [],
      groups: [],
      role: '',
      visible: false,
      okText: '削除',
      title: '',
      submitType: 'delete',
      confirmLoading: false,
      myRole: 3,

      driversLisenceType: enumData.driversLisenceType,
      driversLisenceLoading: true,
      driversLisenceConfirmLoading: false,
      driversLisences: [],
      modalDriversLisenceText: '運転免許追加',
      modalDriversLisenceVisible: false,
      targetDriversLisenceId: 0,
      driversLisenceForm: {
        number: '',
        type: 0,
        condition: 0,
        date_acquisition: '',
        date_expiration: '',
        address: '',
        emergency_contact_full_name: '',
        emergency_contact_relation_ship: '',
        emergency_contact_tel: '',
      },
      driversLisenceRules: {
        number: [
          { max: 256, message: '1023文字までです。', trigger: 'blur' },
        ],
        address: [
          { max: 1023, message: '1023文字までです。', trigger: 'blur' },
        ],
        condition: [
          { max: 1023, message: '1023文字までです。', trigger: 'blur' },
        ],
        emergency_contact_full_name: [
          { max: 1023, message: '1023文字までです。', trigger: 'blur' },
        ],
        emergency_contact_relation_ship: [
          { max: 1023, message: '1023文字までです。', trigger: 'blur' },
        ],
        emergency_contact_tel: [
          { max: 1023, message: '1023文字までです。', trigger: 'blur' },
        ],
      },
      dateAcquisition: moment(),
      dateExpiration: moment(),
      isAcquirableUserOrMe: 0,
    }
  },
  watch: {
    user: function(val) {
      this.role = enumData.userRole.find(item => item.value === this.user.role).label
    },
  },
  created() {
    this.myRole = storeVue.getters.role
    const result = Vue.prototype.$api.send('get', 'users/' + this.$route.params.id)
    result.then(response => {
      this.loading = false
      this.user = response
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': ユーザーの取得に失敗しました。',
        })
        this.loading = false
      })

    const acquirableUserOrMe = Vue.prototype.$api.send('get', 'user/' + this.$route.params.id + '/has_branch_admin_and_is_acquirable_user_or_me')
    acquirableUserOrMe.then(response => {
      this.isAcquirableUserOrMe = response
    })

    const resultBranchesList = Vue.prototype.$api.send('get', 'users/' + this.$route.params.id + '/branches')
    resultBranchesList.then(response => {
      this.belongBranchesListLoading = false
      this.branches = response
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 支店の取得に失敗しました。',
        })
        this.belongBranchesListLoading = false
      })

    const resultGroupsList = Vue.prototype.$api.send('get', 'user/' + this.$route.params.id + '/user_read_range')
    resultGroupsList.then(response => {
      this.readGroupsLoading = false
      this.groups = response
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': グループの取得に失敗しました。',
        })
        this.readGroupsLoading = false
      })

    const resultDriversLisenceList = Vue.prototype.$api.send('get', 'user/' + this.$route.params.id + '/drivers_lisence')
    resultDriversLisenceList.then(response => {
      this.driversLisenceLoading = false
      this.driversLisences = response.map(v => {
        if (v.type) v.type = v.type.split(',')
        return v
      })
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 運転免許の取得に失敗しました。',
        })
        this.driversLisenceLoading = false
      })
  },
  methods: {
    showModal(index) {
      this.title = '本当に ' + this.user.name + ' を削除しますか？'
      this.visible = true
      this.okText = '削除'
      this.submitType = 'delete'
    },
    showUnavailableModal() {
      this.submitType = 'unavailable'
      this.title = this.user.name + ' のアカウントを'
      if (this.user.unavailable) {
        this.title += '利用可能にしますか'
        this.okText = '利用可能にする'
      } else {
        this.title += '停止しますか'
        this.okText = '停止する'
      }
      this.visible = true
    },
    handleOk() {
      const _this = this
      this.confirmLoading = true
      var action
      var path = 'users/' + this.user.id
      if (this.submitType === 'delete') {
        action = 'delete'
      } else {
        action = 'put'
        path += '/unavailable'
      }

      const result = Vue.prototype.$api.send(action, path)
      result.then(response => {
        if (_this.submitType === 'delete') {
          this.$notification['success']({
            message: this.user.name + ' を削除しました',
          })
          this.$router.push('/company')
        } else {
          this.$notification['success']({
            message: this.user.name + ' のアカウントを変更しました',
          })
          _this.user.unavailable = (_this.user.unavailable + 1) % 2
        }
        this.confirmLoading = false
        this.visible = false
      })
        .catch(error => {
          if (_this.submitType === 'delete') {
            this.$notification['error']({
              message: error.status + ': 削除に失敗しました。',
            })
          } else {
            if (error.status === 403) {
              _this.$notification['error']({
                message: error.data.data,
              })
            } else {
              this.$notification['error']({
                message: error.status + ': アカウントの変更に失敗しました。',
              })
            }
          }
          this.confirmLoading = false
          this.loading = false
        })
    },
    handleCancel() { this.visible = false },

    handleCancelDriversLisenceModal() { this.modalDriversLisenceVisible = false },
    onMakeDriversLisence() {
      const targetDate = moment()
      this.modalDriversLisenceVisible = true
      this.targetDriversLisenceId = 0
      this.modalDriversLisenceText = '運転免許追加'
      this.driversLisenceForm.number = ''
      this.driversLisenceForm.type = []
      this.driversLisenceForm.condition = ''
      this.driversLisenceForm.date_acquisition = targetDate.format('YYYY-MM-DD')
      this.driversLisenceForm.date_expiration = targetDate.format('YYYY-MM-DD')
      this.driversLisenceForm.address = ''
      this.driversLisenceForm.emergency_contact_full_name = ''
      this.driversLisenceForm.emergency_contact_relation_ship = ''
      this.driversLisenceForm.emergency_contact_tel = ''
      this.dateAcquisition = targetDate
      this.dateExpiration = targetDate
    },
    updateDriversLisence(id, number, type, condition, dateAcquisition, dateExpiration, address, emergenyName, emergenyRelation, emergenyTel) {
      this.modalDriversLisenceVisible = true
      this.targetDriversLisenceId = id
      this.modalDriversLisenceText = '運転免許更新'
      this.driversLisenceForm.number = number
      this.driversLisenceForm.type = type
      this.driversLisenceForm.condition = condition
      this.driversLisenceForm.date_acquisition = dateAcquisition
      this.driversLisenceForm.date_expiration = dateExpiration
      this.driversLisenceForm.address = address
      this.driversLisenceForm.emergency_contact_full_name = emergenyName
      this.driversLisenceForm.emergency_contact_relation_ship = emergenyRelation
      this.driversLisenceForm.emergency_contact_tel = emergenyTel
      if (dateAcquisition) {
        const momentDateAcquisition = moment(this.driversLisenceForm.date_acquisition, 'YYYY-MM-DD')
        this.dateAcquisition = momentDateAcquisition
      } else {
        this.dateAcquisition = ''
      }
      const momentDateExpiration = moment(this.driversLisenceForm.date_expiration, 'YYYY-MM-DD')
      this.dateExpiration = momentDateExpiration
    },
    handleDeleteDriversLisence() {
      const _this = this
      this.driversLisenceConfirmLoading = true
      const send = Vue.prototype.$api.send('delete', 'drivers_lisences', { id: this.targetDriversLisenceId })
      send.then(response => {
        _this.$notification['success']({
          message: '運転免許を削除しました。',
        })
        this.driversLisences = this.driversLisences.filter(e => e.id !== _this.targetDriversLisenceId)
        this.modalDriversLisenceVisible = false
        this.driversLisenceConfirmLoading = false
      })
        .catch(error => {
          _this.$notification['error']({
            message: error.data.data,
          })
          this.modalDriversLisenceVisible = false
          this.driversLisenceConfirmLoading = false
          console.log(error)
        })
    },
    handleOkDriversLisence() {
      const _this = this
      this.driversLisenceConfirmLoading = true

      if (!this.driversLisenceForm.type || this.driversLisenceForm.type.length === 0) {
        _this.$notification['error']({
          message: '免許種類は必須項目です。',
        })
        this.driversLisenceConfirmLoading = false
        return
      }
      if (!this.dateExpiration) {
        _this.$notification['error']({
          message: '免許証有効期限年月日は必須項目です。',
        })
        this.driversLisenceConfirmLoading = false
        return
      }
      var actionType = 'post'
      let dateAcquisition = ''
      if (this.dateAcquisition) {
        dateAcquisition = this.dateAcquisition.format('YYYY-MM-DD')
      }

      var bodyData = {
        user_id: this.$route.params.id,
        number: this.driversLisenceForm.number,
        type: this.driversLisenceForm.type.join(','),
        condition: this.driversLisenceForm.condition,
        date_acquisition: dateAcquisition,
        date_expiration: this.dateExpiration.format('YYYY-MM-DD'),
        address: this.driversLisenceForm.address,
        emergency_contact_full_name: this.driversLisenceForm.emergency_contact_full_name,
        emergency_contact_relation_ship: this.driversLisenceForm.emergency_contact_relation_ship,
        emergency_contact_tel: this.driversLisenceForm.emergency_contact_tel,
      }
      if (this.targetDriversLisenceId !== 0) {
        actionType = 'put'
        bodyData = {
          id: this.targetDriversLisenceId,
          number: this.driversLisenceForm.number,
          type: this.driversLisenceForm.type.join(','),
          condition: this.driversLisenceForm.condition,
          date_acquisition: dateAcquisition,
          date_expiration: this.dateExpiration.format('YYYY-MM-DD'),
          address: this.driversLisenceForm.address,
          emergency_contact_full_name: this.driversLisenceForm.emergency_contact_full_name,
          emergency_contact_relation_ship: this.driversLisenceForm.emergency_contact_relation_ship,
          emergency_contact_tel: this.driversLisenceForm.emergency_contact_tel,
        }
      }
      this.$refs.driversLisenceRef.validate(valid => {
        const sendDriversLisence = Vue.prototype.$api.send(actionType, 'drivers_lisences', bodyData)
        sendDriversLisence.then(response => {
          _this.$notification['success']({
            message: '運転免許を更新しました。',
          })
          if (this.targetDriversLisenceId !== 0) {
            var targetUpdateDriversLisence = this.driversLisences.find(e => e.id === this.targetDriversLisenceId)
            targetUpdateDriversLisence.number = this.driversLisenceForm.number
            targetUpdateDriversLisence.type = this.driversLisenceForm.type
            targetUpdateDriversLisence.condition = this.driversLisenceForm.condition
            targetUpdateDriversLisence.date_acquisition = dateAcquisition
            targetUpdateDriversLisence.date_expiration = this.dateExpiration.format('YYYY-MM-DD')
            targetUpdateDriversLisence.address = this.driversLisenceForm.address
            targetUpdateDriversLisence.emergency_contact_full_name = this.driversLisenceForm.emergency_contact_full_name
            targetUpdateDriversLisence.emergency_contact_relation_ship = this.driversLisenceForm.emergency_contact_relation_ship
            targetUpdateDriversLisence.emergency_contact_tel = this.driversLisenceForm.emergency_contact_tel
          } else {
            if (response.type) response.type = response.type.split(',')
            this.driversLisences.push(response)
          }
          this.modalDriversLisenceVisible = false
          this.driversLisenceConfirmLoading = false
        })
          .catch(error => {
            if (error.status === 403 || error.status === 400) {
              _this.$notification['error']({
                message: error.data.data,
              })
            } else {
              for (var key in error.data.errors) {
                _this.$refs[key].validateState = 'error'
                _this.$refs[key].validateMessage = error.data.errors[key]
              }
            }
            this.driversLisenceConfirmLoading = false
            console.log(error)
          })
      })
    },
  },
  filters: {
    moment: function (date) {
      if (date) {
        return moment(date).format('YYYY/MM/DD HH:mm')
      } else {
        return ''
      }
    },
    date_str: function (date) {
      if (date) {
        return moment(date).format('YYYY/MM/DD')
      } else {
        return ''
      }
    },
  },
}
</script>

<style>
.list-name {
  font-size: 1.5rem;
}
</style>
